import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import OktaSignIn from '@okta/okta-signin-widget';
import Footer from '../Footer/Footer';
import { fetchLoginConfig, formatOktaConfig } from '../../Services/LoginConfig';
import './OktaLogin.css';
import {  PulseLoader } from 'react-spinners';
import axios from 'axios';
import Logo from '../../hancock-whitney-logo.svg';

const API_URL = process.env.REACT_APP_API_URL;
const ENV_PACKAGE = process.env.REACT_APP_ENV_PACKAGE;

const OktaLogin = () => {
  const [oktaConfig, setOktaConfig] = useState({});
  const [oktaWidgetRendered, setOktaWidgetRendered] = useState(false);
  const [queryParams] = useSearchParams();
  const [csidInit, setCsidInit] = useState(uuidv4);
  const [csid, setCsid] = useState();
  const [interstitialMessage, setInterstitialMessage] = useState('');

  const { fetch: originalFetch } = window;
    window.fetch = async (...args) => {
          let [resource, config ] = args;
          // request interceptor starts
          var url = new URL(resource);

          if(url.pathname === '/oauth2/default/.well-known/openid-configuration') {
            url.href = API_URL + '/oauth2/default/.well-known/openid-configuration';
            resource = url.toString();
          }
          
          // request interceptor ends
          const response = await originalFetch(resource, config);
          // response interceptor here
          return response;
  };

  const handleCsidUpdate = async (event) => {
    event.preventDefault();
    const csid = uuidv4();
    const payload = {
      csid: csid
    };

    try {
 
      await axios.put(`${API_URL}/updatecsid`, payload);

    } catch (error) {
      console.error('PUT Request Error:', error);
    }
    setCsid(csid);
  };

  function addReenterUsernameField(formId) {
    if(document.getElementById('reenterUsername') == null) {
     const fieldsetContainer = document.querySelector('[data-se="o-form-fieldset-container"]');
     
     const reenterUsernameFieldset = document.createElement('div');
     reenterUsernameFieldset.setAttribute('data-se', 'o-form-fieldset-reenter-username');
     reenterUsernameFieldset.className = 'o-form-fieldset o-form-label-top';

     const label = document.createElement('div');
     label.setAttribute('data-se', 'o-form-label');
     label.className = 'okta-form-label o-form-label';
     label.innerHTML = '<label for="reenterUsername">Confirm Username&nbsp;</label>';

     const inputContainer = document.createElement('div');
     inputContainer.setAttribute('data-se', 'o-form-input-container');
     inputContainer.className = 'o-form-input';

     const input = document.createElement('span');
     input.setAttribute('data-se', 'o-form-input-reenter-username');
     input.className = 'o-form-input-name-reenter-username o-form-control okta-form-input-field input-fix';

     const reenterUsernameInput = document.createElement('input');
     reenterUsernameInput.type = 'text';
     reenterUsernameInput.placeholder = 'Confirm Username';
     reenterUsernameInput.name = 'reenterUsername';
     reenterUsernameInput.id = 'reenterUsername';
     reenterUsernameInput.value = '';
     reenterUsernameInput.setAttribute('aria-label', '');
     reenterUsernameInput.setAttribute('autocomplete', 'off');

     input.appendChild(reenterUsernameInput);
     inputContainer.appendChild(input);
     reenterUsernameFieldset.appendChild(label);
     reenterUsernameFieldset.appendChild(inputContainer);
     fieldsetContainer.appendChild(reenterUsernameFieldset);
   }
  }

   function applyRoleButton() {
    const showEyeIcons = document.querySelectorAll('.eyeicon.visibility-16.button-show');
    const hideEyeIcons = document.querySelectorAll('.eyeicon.visibility-off-16.button-hide');
    const resendLink = document.querySelector('.resend-link');
    const checkbox = document.querySelector('.custom-checkbox input[type="checkbox"]');
    const inputs = document.querySelectorAll('input[type="submit"]');

    showEyeIcons.forEach(icon => {
      icon.setAttribute('role', 'button');
      icon.setAttribute('aria-label', 'Show secret');
      icon.tabIndex = 0;
    });

    hideEyeIcons.forEach(icon => {
      icon.setAttribute('role', 'button');
      icon.setAttribute('aria-label', 'Hide secret');
      icon.tabIndex = 0;
    });

    if (resendLink) {
      resendLink.setAttribute('role', 'button');
      resendLink.setAttribute('aria-label', 'Resend email');
      resendLink.tabIndex = 0;
    }

    if (checkbox) {
      checkbox.setAttribute('role', 'checkbox');
	  if (resendLink) {
        resendLink.setAttribute('aria-label', 'Checkbox');
	  }
      checkbox.tabIndex = 0;
    }
    inputs.forEach(input => {
      input.setAttribute('aria-live', 'polite');
    });
  }
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        
        const data = await fetchLoginConfig(queryParams, csidInit);
        const formattedConfig = formatOktaConfig(data);
        setOktaConfig(formattedConfig);
      } catch (error) {
        console.error('Error fetching configuration:', error);
      }
    };
    fetchConfig();
  }, [queryParams, csidInit]);

  useEffect(() => {
    if(oktaConfig.csid) {
      setCsid(oktaConfig.csid);
    }
  }, [oktaConfig]);

  useEffect(() => {
    if(csid) {
      const gdOLBwrapper = () => {
        const gdOLB = () => {
          var result = {
            "c" : csid,
          }
          return result;
        }
        return gdOLB();
      }
      
      window.gdOLB = gdOLBwrapper;

      //window.gdOLB = gdOLB;
      gdOLBwrapper();
    }
  }, [csid]);

  const validateAndEnableContinue = useCallback(() => {
    const usernameInput = document.getElementsByName('identifier')[0];
    const reenterUsernameInput = document.getElementById('reenterUsername');
  
    // Continue buttons for each available authenticator on Unlock Account page
    const continueButton = document.querySelectorAll("#okta-sign-in .authenticator-row .authenticator-button .button");
  
    // Submit button on forgot password screen
    const submitButton = document.querySelector("#okta-sign-in.auth-container input[type=submit]");
  
    if (usernameInput && reenterUsernameInput && continueButton) {
      if (usernameInput.value && reenterUsernameInput.value && usernameInput.value === reenterUsernameInput.value) {
        continueButton.forEach(function (currentValue, currentIndex, listObj) {
          continueButton[currentIndex].style.opacity = '1';
          continueButton[currentIndex].style.pointerEvents = 'auto';
          addMatchWarning(false);
        });
      } else {
        continueButton.forEach(function (currentValue, currentIndex, listObj) {
          continueButton[currentIndex].style.opacity = '0.6';
          continueButton[currentIndex].style.pointerEvents = 'none';
          addMatchWarning(true);
        });
      }
    }
  
    if (usernameInput && reenterUsernameInput && submitButton) {
      if (usernameInput.value && reenterUsernameInput.value && usernameInput.value === reenterUsernameInput.value) {
        submitButton.removeAttribute('disabled');
        submitButton.style.pointerEvents = 'auto';
        addMatchWarning(false);
      } else {
        submitButton.setAttribute('disabled', 'disabled');
        submitButton.style.pointerEvents = 'none';
        addMatchWarning(true);
      }
    }
  }, [])

  useEffect(() => {
    if (oktaConfig.baseUrl) {
      const signInConfig = {
        baseUrl: oktaConfig.baseUrl,
        clientId: oktaConfig.clientId,
        redirectUri: oktaConfig.redirectUri,
        logo: Logo,
        logoText: oktaConfig.logoText,
        i18n: oktaConfig.i18n,
        useClassicEngine: oktaConfig.useClassicEngine,
        stateToken: oktaConfig.stateToken,
        otp: oktaConfig.otp,
        state: oktaConfig.state,
        features: oktaConfig.features,
        helpLinks: oktaConfig.helpLinks,
        authParams: oktaConfig.authParams
      };

      const signIn = new OktaSignIn(signInConfig);

      signIn.authClient.session.exists().then((exists) => {
        if (exists) {
          signIn.authClient.token.getWithRedirect({
            nonce: oktaConfig.authParams.nonce,
          });
        } else {
          signIn.showSignInAndRedirect({el: "#okta-login-container" },(resp) => {
			      return resp;
          });
        }
      });

      signIn.on('ready', function (context) {
        applyRoleButton();
        const usernameElement = document.getElementById('input28');
        const passwordElement = document.getElementById('input36');

        if (oktaConfig.username && oktaConfig.password) {
            signIn.hide();
            setOktaWidgetRendered(false);
            usernameElement.value = oktaConfig.username;
            passwordElement.value = oktaConfig.password;
            usernameElement.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }));
            passwordElement.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }));
            document.querySelector('#okta-sign-in.auth-container input[type="button"], #okta-sign-in.auth-container input[type="submit"]').click();

            // Storefront request
            (function () {var f = document,e = window,i = e.location.protocol,b = [["src", [i == "https:" ? "https:/" : "http:/", "cotton.hancockwhitney.com/" + ENV_PACKAGE + "/topHW.js?dt=security_question&r=" + Math.random()].join("/")],["type", "text/javascript"],["async", true]],c = f.createElement("script"),h = f.getElementsByTagName("head")[0];setTimeout(function () {for (var d = 0, l = b.length; d < l; d++) {c.setAttribute(b[d][0], b[d][1])}h.appendChild(c)}, 0)})();

        } else {
          //Not a storefront request
          (function () {var f = document,e = window,i = e.location.protocol,b = [["src", [i == "https:" ? "https:/" : "http:/", "cotton.hancockwhitney.com/" + ENV_PACKAGE + "/topHW.js?dt=login&r=" + Math.random()].join("/")],["type", "text/javascript"],["async", true]],c = f.createElement("script"),h = f.getElementsByTagName("head")[0];setTimeout(function () {for (var d = 0, l = b.length; d < l; d++) {c.setAttribute(b[d][0], b[d][1])}h.appendChild(c)}, 0)})();

        }
      });
      signIn.on('afterRender', function (context) {
        applyRoleButton();
        const usernameElement = document.getElementsByName('identifier');
        const passwordElement = document.getElementsByName('credentials.passcode');
        const helpLinkElement = document.getElementsByClassName('link js-help');

        if (oktaConfig.username && oktaConfig.password) {
          
          // If username/password POST, only show the widget if a screen other than primary auth is present
          if(context.controller !== 'primary-auth') {
            signIn.show();
            setOktaWidgetRendered(true);
          } else {
            window["olbHancockWhitney"]("login");
          }
        } else {
          setOktaWidgetRendered(true);
        }

        if(usernameElement[0]) {
          usernameElement[0].setAttribute('placeholder', 'Enter Username');
          usernameElement[0].setAttribute('autocomplete', 'username');
        }
        if(passwordElement[0]) {
          passwordElement[0].setAttribute('placeholder', 'Enter Password');
          passwordElement[0].setAttribute('autocomplete', 'new-password'); // Need to ask how could new password be autocomplete ?
        }
        if(helpLinkElement[0] && (!queryParams.get('SSOClientID') || !queryParams.get('SSOClientID').includes("OAO"))) {
          helpLinkElement[0].setAttribute('target', '_blank');
          helpLinkElement[0].setAttribute('rel', 'noreferrer');
        }

        if(context.controller === 'primary-auth') {
          //
        } else {
          window["olbHancockWhitney"]("security_question");
        }

        if((context.controller === null) || (context.controller === 'forgot-password')) {
          
          if (usernameElement[0]) {
            addReenterUsernameField(usernameElement[0].id);

            usernameElement[0].addEventListener('input', validateAndEnableContinue);
          }

          const reenterUsernameInputElement = document.getElementById('reenterUsername');

          if (reenterUsernameInputElement) {
            reenterUsernameInputElement.addEventListener('input', validateAndEnableContinue);
          }
        }
        // Manually override text for incorrect Security Question Answer 
        if (context.controller === 'mfa-verify-question') {
          waitForElm('.o-form-explain').then((elm) => {
            elm.innerText ="Invalid answer. Please try again.";
        });

        }
        // Remove whitespace after 'your phone' on phone verification screens
        if (context.controller === 'mfa-verify-passcode') {
          const yourPhoneElement = document.getElementsByClassName('no-translate');
          for(const element in yourPhoneElement) {
            if(yourPhoneElement[element].textContent === '.') {
              yourPhoneElement[element].previousSibling.parentNode.removeChild(yourPhoneElement[element].previousSibling);
            }
          }

          // Auto click "Send verification email" screen
          const enterVerificationLink = document.getElementsByClassName('enter-auth-code-instead-link');
          if(enterVerificationLink[0] != null) {
            enterVerificationLink[0].click();
          }

        }

        // Modify 'Cancel' links that do nothing to instead redirect to landing page
        if (context.controller === 'enroll-choices') {
          // Remove cancel button on activation landing page
          const cancelLinkElementActivation = document.getElementsByClassName('js-cancel');
          cancelLinkElementActivation[0].replaceWith(cancelLinkElementActivation[0].cloneNode(true));
          cancelLinkElementActivation[0].addEventListener('click', 
          //async function
          (event) => {
            event.preventDefault();
            cancelButton(signIn.authClient).then(function(result) {window.location = API_URL});
          }
          );
        }
        if (!context.controller) {
          // Remove cancel button on activation landing page
          const cancelLinkElementError = document.getElementsByClassName('js-go-back');
          if(cancelLinkElementError[0]) {
            cancelLinkElementError[0].href = API_URL;
            cancelLinkElementError[0].replaceWith(cancelLinkElementError[0].cloneNode(true));
          }
        }

        // Remove the "Set up required" element
        const setupRequiredElement = document.querySelector('.authenticator-list-title');
        if (setupRequiredElement) {
          setupRequiredElement.remove();
        }
        const usageTextElements = document.querySelectorAll('.authenticator-usage-text');
        usageTextElements.forEach(element => {
          element.remove();
        });

        const errorContainer = document.querySelector('.o-form-error-container');
        const messagesContainer = errorContainer.querySelector('.ion-messages-container');
        if (messagesContainer) {
          errorContainer.removeChild(messagesContainer);
        }

        //for placeholder for confirm password
        const confirmPasswordInput = document.querySelector('.o-form-input-name-confirmPassword input');
        if (confirmPasswordInput) {
          confirmPasswordInput.placeholder = 'Confirm Password';
        }

        const cancelButton = document.querySelector('a.link.js-cancel');
        if (cancelButton) {
          cancelButton.addEventListener('click', handleCsidUpdate, false);
        }

      });
      signIn.on('afterError', function (context, error) {
        signIn.show();
        setOktaWidgetRendered(true);
        applyRoleButton();


      });
    }
  }, [oktaConfig]);

  const togglePasswordVisibility = (event, icon) => {
    if (event.key === 'Enter' || event.code === 'Space') {
      event.preventDefault();
      const input = icon.closest('.o-form-input').querySelector('input');
      if (input) {
        const show = input.type === 'password';
        input.type = show ? 'text' : 'password';
        icon.style.display = 'none';
        const correspondingIcon = show ? icon.nextElementSibling : icon.previousElementSibling;
        if (correspondingIcon) {
          correspondingIcon.style.display = 'block';
          correspondingIcon.focus();
        }
      }
    }
  };
  
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Tab') {
        const showEyeIcons = document.querySelectorAll('.eyeicon.visibility-16.button-show');
        const hideEyeIcons = document.querySelectorAll('.eyeicon.visibility-off-16.button-hide');
  
        showEyeIcons.forEach(icon => {
          icon.setAttribute('role', 'button');
          icon.setAttribute('aria-label', 'Show secret');
          icon.tabIndex = 0;
        });
  
        hideEyeIcons.forEach(icon => {
          icon.setAttribute('role', 'button');
          icon.setAttribute('aria-label', 'Hide secret');
          icon.tabIndex = 0;
        });
  
        const resendLink = document.querySelector('.resend-link');
        if (resendLink) {
          resendLink.setAttribute('role', 'button');
          resendLink.setAttribute('aria-label', 'Resend email');
          resendLink.tabIndex = 0;
        }
      } else if (event.key === 'Enter' || event.code === 'Space') {
        const activeElement = document.activeElement;
        const isShowEyeIcon = activeElement.classList.contains('visibility-16') && activeElement.classList.contains('button-show');
        const isHideEyeIcon = activeElement.classList.contains('visibility-off-16') && activeElement.classList.contains('button-hide');
  
        if (isShowEyeIcon || isHideEyeIcon) {
          togglePasswordVisibility(event, activeElement);
        } else if (activeElement.classList.contains('button-primary')) {
          const form = document.querySelector('form.password-authenticator');
          if (form) {
            form.submit();
          }
        } else if (activeElement.classList.contains('resend-link')) {
          activeElement.click();
        }
      } else if (event.code === 'Space') {
        const activeElement = document.activeElement;
        if (activeElement.getAttribute('type') === 'checkbox') {
          activeElement.checked = !activeElement.checked;
          event.preventDefault();
        }
      }
    };
  
    document.addEventListener('keydown', handleKeyDown);
  
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  function waitForElm(selector) {
    return new Promise((resolve) => {
      const element = document.querySelector(selector);
      if (element) {
        resolve(element);
      }

      const observer = new MutationObserver((mutations) => {
        const targetElement = document.querySelector(selector);
        if (targetElement) {
          resolve(targetElement);
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }
  
  function addMatchWarning(enabled) {
    const usernameInput = document.getElementsByName('identifier')[0];
    const reenterUsernameInput = document.getElementById('reenterUsername');
  
    if (enabled && usernameInput.value && reenterUsernameInput.value) {
      if (!document.getElementById('noMatchError')) {
        const fieldsetContainer = document.querySelector('[data-se="o-form-input-reenter-username"]');
        const warning = document.createElement('p');
        warning.id = 'noMatchError';
        warning.className = 'okta-form-input-error o-form-input-error o-form-explain';
        warning.role = 'alert';
        warning.textContent = 'Your Usernames do not match.';
  
        const span = document.createElement('span');
        span.id = 'noMatchErrorSpan';
        span.className = 'icon icon-16 error-16-small';
  
        warning.prepend(span);
        fieldsetContainer.parentElement.appendChild(warning);
      }
    } else {
      const noMatchError = document.getElementById('noMatchError');
      const noMatchErrorSpan = document.getElementById('noMatchErrorSpan');
      if (noMatchError) {
        noMatchError.remove();
      }
      if (noMatchErrorSpan) {
        noMatchErrorSpan.remove();
      }
    }
  }
  useEffect(() => {
    // Function to update the ARIA live region with status messages
    const updateInterstitialMessage = () => {
      const ariaLiveRegion = document.getElementById('interstitialMessage');
      ariaLiveRegion.textContent = interstitialMessage;
    };
    updateInterstitialMessage();
  }, [interstitialMessage]);
  

  return (
    <div>
    {/* ARIA Live Region */}
    <div id="interstitialMessage" style={{ position: 'absolute', overflow: 'hidden', clip: 'rect(0 0 0 0)', height: '1px', width: '1px', margin: '-1px', padding: '0', border: '0' }} role="status" aria-live="polite">{interstitialMessage}</div>

    {!oktaWidgetRendered && <div class="loading"><div class="spinner">
      <PulseLoader color="#2459a8" />
    </div>
    </div>}
    <div id="okta-login-container"></div>
    {oktaWidgetRendered && <Footer />}
  </div>
  );
};

function uuidv4(){
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16) );
}

export default OktaLogin;
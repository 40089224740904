import './App.css';
//import Terms from './Components/Terms/Terms';
//import Continue from './Components/Continue/Continue';
//import Unauth from './Components/Unauth/Unauth';
//import ForbiddenPage from './Components/ForbiddenPage/ForbiddenPage';
//import Failure from './Components/Failure/Failure';
//import Download from './Components/Download/Download';
import OktaLogin from './Components/OktaLogin/OktaLogin';
import AppRedirect from './Components/AppRedirect/AppRedirect';
//import OktaLogout from './Components/OktaLogout/OktaLogout';
import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {  PulseLoader } from 'react-spinners';

const Terms = lazy(() => import('./Components/Terms/Terms'));
const Continue = lazy(() => import('./Components/Continue/Continue'));
const Unauth = lazy(() => import('./Components/Unauth/Unauth'));
const ForbiddenPage = lazy(() => import('./Components/ForbiddenPage/ForbiddenPage'));
const Failure = lazy(() => import('./Components/Failure/Failure'));
const Download = lazy(() => import('./Components/Download/Download'));
//const OktaLogin = lazy(() => import('./Components/OktaLogin/OktaLogin'));
const OktaLogout = lazy(() => import('./Components/OktaLogout/OktaLogout'));


function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      await new Promise(resolve => resolve());
      // await new Promise(resolve => setTimeout(resolve, 2000000));
      setLoading(false);
    };

    loadData();
  }, []);

  return (
    <>
      <div className={`app ${loading ? 'loading' : ''}`}>
        {loading && (
          <div className="spinner">
            <PulseLoader color="#2459a8" />
          </div>
        )}
        {!loading &&
          <Suspense fallback={<div className="spinner"><PulseLoader color="#2459a8" /></div>}>
		    <BrowserRouter basename={process.env.PUBLIC_URL} >
              <Routes>
                <Route path="/download" element={<Download />}></Route>
                <Route path='/terms' element={<Terms />}></Route>
                <Route path='/redirect' element={<AppRedirect />}></Route>
                <Route path='/continue' element={<Continue />}></Route>
                <Route path='/unauth' element={<Unauth />}></Route>
                <Route path='/logout' element={<OktaLogout />}></Route>
                <Route path='/403' element={<ForbiddenPage />}></Route>
                <Route path='/failure' element={<Failure />}></Route>
                <Route path="/" element={<OktaLogin />}></Route>
                {/* <Route path="/:/:SSOClientID/:SAMLRequest/:RelayState" element={<OktaLogin />} /> */}
              </Routes>
            </BrowserRouter>
		  </Suspense>
        }
      </div>
    </>
  );
}

export default App;
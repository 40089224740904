import './Footer.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const PRIVACY_URL = process.env.REACT_APP_ORG_PRIVACY_URL;
const LEGAL_URL = process.env.REACT_APP_ORG_LEGAL_URL;
const ORG_WEBSITE_URL = process.env.REACT_APP_ORG_WEBSITE_URL;

function Footer() {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer id="footer" className="footer">
      <hr />
      <div className="footertext">
        <p>
          &emsp;&emsp;&emsp;Hancock Whitney and the Hancock Whitney logo are
          federally-registered trademarks of Hancock Whitney Corporation.
          Copyright © {currentYear} Hancock Whitney Bank
        </p>
        <p>
          &emsp;&emsp;&emsp;<a href={PRIVACY_URL} target="_blank" rel="noreferrer">privacy</a>&emsp;&emsp;|&emsp;&emsp;<a
            href={LEGAL_URL} target="_blank" rel="noreferrer">legal</a>&emsp;&emsp;|&emsp;&emsp;<a href={ORG_WEBSITE_URL} target="_blank" rel="noreferrer">hancockwhitney.com</a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
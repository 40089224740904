import './AppRedirect.css';
import React, { useEffect, useState } from "react";
import {  PulseLoader } from 'react-spinners';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

function AppRedirect() {
  const [apiData, setApiData] = useState(null);
  const [isReady, setReady] = useState(false);
  const [failure, setFailure] = useState(false);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}/redirectapi`);
      setApiData(response.data.requestBody);
      setReady(true);
    } catch (error) {
      console.error('Error fetching data:', error);
      setFailure(true);
    }
  
  };

  useEffect(() => {
    fetchData();
    //document.getElementById("redirectForm").submit();
  }, []);

  useEffect(() => {
    if(document.getElementById("redirectForm") !== null) {
      document.getElementById("redirectForm").submit();
    }
  }, [isReady]);

  if(!isReady) {
    return <div class="loading">
        <div class="spinner">
          <PulseLoader color="#2459a8" />
        </div>
      </div>
  } 
  if(isReady) {

    return <form id="redirectForm" method="POST" action={apiData.targetUrl}>

    <input
      type="hidden"
      name="idToken"
      value={apiData.idToken}
    ></input>
    {apiData.jwsToken &&
    <input
      type="hidden"
      name="jwsToken"
      value={apiData.jwsToken}
    ></input>
    }
    </form>
  }

  document.getElementById("redirectForm").submit();
}

export default AppRedirect;
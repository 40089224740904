import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const i18n = {
  en: {
    'oie.verification.switch.authenticator':'Back to verification methods',
    'oie.email.verify.subtitle.text.with.email':'Send a verification email to <$1>{0}</$1>.',
    'oie.primaryauth.submit':'Log In',
    'oie.phone.label':'Verify by Phone',
    'oie.email.label':'Verify by Email',
    'oie.remember':'Remember Me',
    'primaryauth.title':'Please log in with your credentials to view your account details.',
    //'signin':'Please log in with your credentials to view your account details.',
    'errors.E0000004':'Invalid credentials.',
    'oie.select.authenticators.verify.title':'Please select your preferred method to verify your identity.',
    'oie.select.authenticators.verify.subtitle':' ',
    'oie.phone.verify.title':'Please verify your identity.',
    'oie.phone.verify.sms.codeSentText':'We sent a code to',
    'oie.phone.alternate.title':'your phone',
    'oie.phone.verify.enterCodeText':' ',
    'oie.phone.verify.sms.sendText':'Carrier messaging charges may apply to',
    'oie.phone.carrier.charges':' ',
    'password.forgot.email.or.username.placeholder':'Username',
    'password.forgot.email.or.username.tooltip':'Username',
    'password.reset.title.generic':'Forgot Password ',
    'oie.password.confirmPasswordLabel':'Confirm Password',
    'mfa.challenge.verify':'Verify & Continue',
    'oie.phone.sms.primaryButton':'Text a 6-digit code to your phone',
    'oie.phone.call.secondaryButton':'Call your phone with a 5-digit code',
    'oform.next':'Continue',
    'account.unlock.title':'Unlock Account',
	'unlockaccount':'Unlock Account',
    'oie.verify.authenticator.button.text':'Continue',
    'goback':'Cancel',
    'forgotpassword':'Forgot Password',
    'oform.errorbanner.title':'We found errors.',
    'api.authn.error.PASSCODE_INVALID':'Invalid code. Please try again.',
    'oie.phone.verify.sms.resendText':"Haven't received a text message?",
    'password.error.match':'Your passwords do not match.',
    'oie.password.newPasswordLabel':'New Password',
    'oie.email.challenge.mfa.title':'Please verify your identity.',
    'oie.email.verify.primaryButton':'Send verification email',
    'registration.error.password.passwordRequirementsNotMet':'Your New Password does not meet the requirements.',
    'oie.selfservice.unlock_user.landing.to.app.success.message':'Account successfully unlocked! Please enter your password to view your account details.',
    'oie.email.verify.subtitle.text.without.email':' Send a verification email by clicking on \Send verification email\"."',
    'oie.select.authenticators.enroll.subtitle':'Security methods help protect your account by ensuring only you have access. Please set up the required security methods.',
    'oie.phone.authenticator.description':'Provide a phone number that will be used to verify your identity.',
    'oie.security.question.authenticator.description':'Choose a security question and answer that will be used for account recovery.',
    'oie.password.enroll.title':'Please choose a password for your account.',
    'oie.select.authenticators.enroll.title':'Set up security methods',
    'oie.password.authenticator.description':'Account successfully unlocked! Please enter your password to view your account details. ',
    'oie.password.challenge.title':'Choose a password for your account.',
    'oie.password.passwordLabel':'Enter Password',
    'oie.configuration.error':'Your link has expired. Please contact us at 1-800-448-8812 for assistance.',
    'idx.missing.activation.token':'Your link has expired. Please contact us at 1-800-448-8812 for assistance.',
    'idx.expired.activation.token':'Your link has expired. Please contact us at 1-800-448-8812 for assistance.',
    'oie.activation.request.email.title.invalid':' ',
    'oie.activation.request.email.title.expire':' ',
    'oie.password.reset.verification':'Please verify your identity with one of the following security methods to reset your password.',
    'oie.email.mfa.title':'Please verify your identity.',
    'oie.security.question.challenge.title':'Please verify your identity.',
    'oie.session.expired':'Your link has expired. Please contact us at 1-800-448-8812 for assistance.',
    'oie.enroll.switch.authenticator':'Back to verification methods'
  }
}

export const fetchLoginConfig = async (queryParams, csid) => {
  try {

    const url = `${API_URL}/loginconfig`;
    const response = await axios.get(url, { 
      params: {
        SSOClientID: queryParams.get('SSOClientID'), 
        productType: queryParams.get('productType'), 
        ncinoApplicationID: queryParams.get('ncinoApplicationID'), 
        otp: queryParams.get('otp'), 
        recovery_state: queryParams.get('recovery_state'),
        activation_token: queryParams.get('activation_token') || queryParams.get('activationToken'),
        state_token: queryParams.get('state_token'),
        recovery_token: queryParams.get('recovery_token'),
        csid: csid
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching login configuration:', error);
    throw error;
  }
};

export const formatOktaConfig = (data) => {

  const _ = require('lodash');

  return {

    baseUrl: data.baseUrl,
    clientId: data.clientId,
    redirectUri: data.redirectUri,
    logo: data.logo,
    logoText: data.logoText,
    useClassicEngine: data.useClassicEngine,
    stateToken: data.stateToken,
    otp: data.otp,
    state: data.state,
    authParams: {
      issuer: data.authParams.issuer,
      responseType: data.authParams.responseType,
      responseMode: data.authParams.responseMode,
      pkce: data.authParams.pkce,
      nonce: data.authParams.nonce,
      scopes: data.authParams.scopes,
      display: data.authParams.display,
      codeChallenge: data.authParams.codeChallenge,
      codeChallengeMethod: data.authParams.codeChallengeMethod,
      activationToken: data.authParams.activationToken,
    },
    features: {
      registration: data.features.registration,
      rememberMe: data.features.rememberMe,
      rememberMyUsernameOnOIE: data.features.rememberMyUsernameOnOIE,
      showIdentifier: data.features.showIdentifier,
      emailMagicLink: data.features.emailMagicLink,
      deviceFingerprinting: data.features.deviceFingerprinting,
      useDeviceFingerprintForSecurityImage: data.features.useDeviceFingerprintForSecurityImage,
    },
    helpLinks: {
      help: data.helpLinks.help,
    },
    //i18n: data.i18n,
    //i18n: {en: {help: data.i18n.en.help}},
    //i18n: {...i18n, {en: help: {...data.i18n.en.help}}},
    //i18n: Object.assign({i18n, en: {help: data.i18n.en.help}}), //    {...i18n, {en: help: {...data.i18n.en.help}}},
    i18n: _.merge(i18n,{en: {help: data.i18n.en.help}}),
    //i18n: deepMergeObjects(i18n, {'en': {'help': data.i18n.en.help}}),
    //i18n['en']: {en: {help: data.i18n.en.help}},
    username: data.username,
    password: data.password,
    csid: data.csid
  };
};